import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Paper,
    Typography,
    Select,
    MenuItem,
    ButtonGroup,
    Button,
    Tabs,
    Tab
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import useResponsive from '../hooks/useResponsive';

const BracketContainer = styled('div')({
    display: 'flex',
    padding: '20px',
    gap: '40px',
    overflowX: 'auto',
    backgroundColor: '#fff'
});

const RoundSwitcher = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
    marginBottom: '16px',
}));

const RoundButton = styled(Button)(({ selected }) => ({
    minWidth: '48px',
    height: '48px',
    borderRadius: '50%',
    padding: 0,
    backgroundColor: selected ? '#1976d2' : '#fff',
    color: selected ? '#fff' : '#000',
    border: '1px solid #e0e0e0',
    '&:hover': {
        backgroundColor: selected ? '#1565c0' : '#f5f5f5',
    },
}));

const Round = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    minWidth: '300px',
    '& h2': {
        margin: '0 0 16px 0',
        fontSize: '16px',
        fontWeight: 500,
    }
});

const RoundTitle = styled(Typography)({
    fontSize: '24px',
    fontWeight: 500,
    marginBottom: '16px',
    paddingBottom: '8px',
    borderBottom: '1px solid #e0e0e0',
    position: 'relative',
    '&.active': {
        color: '#1976d2',
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: -1,
            left: 0,
            width: '100%',
            height: '2px',
            backgroundColor: '#1976d2',
        }
    }
});

const Match = styled(Card)(({ theme }) => ({
    width: '100%',
    position: 'relative',
    backgroundColor: '#f8f9fa',
    boxShadow: 'none',
    borderRadius: '4px',
    '&::after': {
        content: '""',
        position: 'absolute',
        right: '-40px',
        top: '50%',
        width: '40px',
        height: '2px',
        backgroundColor: '#e0e0e0',
    },
    '&:last-child::after': {
        display: 'none'
    }
}));

const MatchContent = styled(CardContent)({
    padding: '8px !important',
});

const Player = styled('div')(({ winner }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    position: 'relative',
    '& > span:first-of-type': {
        fontWeight: winner ? 500 : 400,
    }
}));

const Score = styled('div')({
    display: 'flex',
    gap: '4px',
    alignItems: 'center'
});

const ScoreNumber = styled('span')({
    minWidth: '16px',
    textAlign: 'center',
});

const Seed = styled('span')({
    color: '#666',
    fontSize: '0.9em',
    marginLeft: '4px',
});

const DateTime = styled('div')({
    fontSize: '0.85em',
    color: '#666',
    marginBottom: '8px',
    backgroundColor: '#eceef0',
    padding: '8px',
    borderRadius: '4px',
});

const Location = styled('span')({
    fontSize: '0.85em',
    color: '#666',
    float: 'right',
});

const WinnerCheck = styled(CheckIcon)({
    color: '#2e7d32',
    fontSize: '16px',
    marginRight: '4px',
});

// Helper function to calculate the midpoint offset for a match
const calculateMatchOffset = (roundIndex, matchIndex, totalMatches) => {
    const MATCH_HEIGHT = 140; // Height of a single match card
    const MATCH_SPACING = 30; // Base spacing between matches in the same round

    if (roundIndex === 0) return 0;

    // For semifinals (round 1)
    if (roundIndex === 1) {
        if (matchIndex === 0) {
            return MATCH_HEIGHT / 2
        }
        // Calculate the midpoint between two consecutive quarterfinal matches
        return MATCH_HEIGHT + (MATCH_SPACING);
    }

    // For finals (round 2)
    if (roundIndex === 2) {
        if (matchIndex === 0) {
            return (MATCH_HEIGHT) * 1.5 + (MATCH_SPACING) * 1;
        }
        // Calculate the midpoint between two semifinal matches
        return (MATCH_HEIGHT) * 3 + (MATCH_SPACING) * 3;
    }

    return 0;
};

const ROUND_CONFIG = [
    { key: 'r64', label: 'R64', mobileLabel: 'R64', title: 'Round of 64`' },
    { key: 'r32', label: 'R32', mobileLabel: 'R32', title: 'Round of 32' },
    { key: 'r16', label: 'R16', mobileLabel: 'R16', title: 'Round of 16' },
    { key: 'quarterfinals', label: 'Quarterfinals', mobileLabel: 'QF', title: 'Quarterfinals' },
    { key: 'semifinals', label: 'Semifinals', mobileLabel: 'SF', title: 'Semifinals' },
    { key: 'finals', label: 'Finals', mobileLabel: 'F', title: 'Finals' }
];


export const TennisBracket = ({
    matches = {},
}) => {
    const categories = Object.keys(matches);
    const [selectedCategory, setSelectedCategory] = React.useState(categories[0] || '');
    const availableStages = React.useMemo(() =>
        selectedCategory ? Object.keys(matches[selectedCategory]) : [],
        [matches, selectedCategory]);

    const [selectedStage, setSelectedStage] = React.useState(availableStages[0] || '');

    // Get current matches based on selected category and stage
    const currentMatches = React.useMemo(() =>
        selectedCategory && selectedStage ? matches[selectedCategory][selectedStage] : {},
        [matches, selectedCategory, selectedStage]);

    // Get available rounds based on current matches
    const availableRounds = React.useMemo(() =>
        ROUND_CONFIG.filter(round =>
            currentMatches[round.key] && currentMatches[round.key].length > 0
        ),
        [currentMatches]);

    const [visibleRounds, setVisibleRounds] = React.useState(() =>
        availableRounds.slice(0, 3).map(round => round.key)
    );

    const [selectedRound, setSelectedRound] = React.useState(() =>
        availableRounds[1]?.label || availableRounds[0]?.label
    );

    const isDesktop = useResponsive('up', 'lg');

    if (!categories.length) {
        return <Typography>No tournament data available</Typography>;
    }

    if (!availableStages.length) {
        return <Typography>No stages available for selected category</Typography>;
    }

    if (!availableRounds.length) {
        return <Typography>No rounds available for selected stage</Typography>;
    }

    const handleRoundChange = (_, newRound) => {
        const roundIndex = availableRounds.findIndex(r => r.label === newRound);
        if (roundIndex === -1) return;

        let newVisibleRounds;
        if (roundIndex === 0) {
            newVisibleRounds = availableRounds.slice(0, 3).map(r => r.key);
        } else if (roundIndex === availableRounds.length - 1) {
            newVisibleRounds = availableRounds.slice(-3).map(r => r.key);
        } else {
            newVisibleRounds = availableRounds.slice(roundIndex - 1, roundIndex + 2).map(r => r.key);
        }

        setVisibleRounds(newVisibleRounds);
        setSelectedRound(newRound);
    };

    const handleCategoryChange = (event) => {
        const newCategory = event.target.value;
        setSelectedCategory(newCategory);
        // Reset stage to first available stage in new category
        const newStages = Object.keys(matches[newCategory]);
        setSelectedStage(newStages[0]);
        // Reset rounds based on new matches
        const newMatches = matches[newCategory][newStages[0]];
        const newAvailableRounds = ROUND_CONFIG.filter(round =>
            newMatches[round.key] && newMatches[round.key].length > 0
        );
        setVisibleRounds(newAvailableRounds.slice(0, 3).map(round => round.key));
        setSelectedRound(newAvailableRounds[1]?.label || newAvailableRounds[0]?.label);
    };

    // Handle stage change
    const handleStageChange = (event) => {
        const newStage = event.target.value;
        setSelectedStage(newStage);
        // Reset rounds based on new matches
        const newMatches = matches[selectedCategory][newStage];
        const newAvailableRounds = ROUND_CONFIG.filter(round =>
            newMatches[round.key] && newMatches[round.key].length > 0
        );
        setVisibleRounds(newAvailableRounds.slice(0, 3).map(round => round.key));
        setSelectedRound(newAvailableRounds[1]?.label || newAvailableRounds[0]?.label);
    };

    const renderPlayer = (player) => (
        <Player winner={player.winner}>
            <span>
                {player.name}
                {player.seed && <Seed>[{player.seed}]</Seed>}
            </span>
            <Score>
                {player.winner && <WinnerCheck />}
                {player.score?.map((s, i) => (  // Added optional chaining
                    <ScoreNumber key={i}>{s}</ScoreNumber>
                ))}
                {player.walkover && <span>W/O</span>}
            </Score>
        </Player>
    );

    const renderMatch = (match) => (
        <Match elevation={1}>
            <MatchContent>
                <DateTime>
                    {match.datetime || 'Time TBD'}
                    <Location>{match.location || 'Court TBD'}</Location>
                </DateTime>
                {renderPlayer(match.player1)}
                {renderPlayer(match.player2)}
            </MatchContent>
        </Match>
    );

    // Get the rounds that should be displayed
    const getDisplayRounds = () => {
        return visibleRounds.map(roundKey => ({
            title: ROUND_CONFIG.find(r => r.key === roundKey)?.title,
            matches: currentMatches[roundKey]  // Changed from matches[roundKey] to currentMatches[roundKey]
        }));
    };




    return (
        <>
            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                {isDesktop ? (
                    <Tabs
                        value={selectedRound}
                        onChange={handleRoundChange}
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#1976d2',
                            }
                        }}
                    >
                        {availableRounds.map(({ label, key }) => (
                            <Tab
                                key={label}
                                label={label}
                                value={label}
                                sx={{
                                    color: visibleRounds.includes(key)  // Changed from label.toLowerCase().replace(' ', '')
                                        ? '#1976d2'
                                        : 'text.primary',
                                    '&.Mui-selected': {
                                        color: '#1976d2',
                                    }
                                }}
                            />
                        ))}
                    </Tabs>
                ) : null}

                <Select
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    size="small"
                >
                    {categories.map(category => (
                        <MenuItem key={category} value={category}>{category}</MenuItem>
                    ))}
                </Select>

                <Select
                    value={selectedStage}
                    onChange={handleStageChange}
                    size="small"
                >
                    {availableStages.map(stage => (
                        <MenuItem key={stage} value={stage}>{stage}</MenuItem>
                    ))}
                </Select>
            </Box>

            {/* Mobile round switcher */}
            {!isDesktop && (
                <RoundSwitcher>
                    {availableRounds.map(({ label, mobileLabel }) => (
                        <RoundButton
                            key={label}
                            selected={selectedRound === label}
                            onClick={() => handleRoundChange(null, label)}
                            variant="outlined"
                        >
                            {mobileLabel}
                        </RoundButton>
                    ))}
                </RoundSwitcher>
            )}

            <BracketContainer>
                {(isDesktop ? getDisplayRounds() : [
                    {
                        title: ROUND_CONFIG.find(r => r.label === selectedRound)?.title,
                        matches: currentMatches[ROUND_CONFIG.find(r => r.label === selectedRound)?.key]
                    }
                ]).map((round, roundIndex) => (
                    <Round key={round.title} sx={{ minWidth: isDesktop ? '300px' : '100%' }}>
                        <RoundTitle
                            variant="h2"
                            className={isDesktop ?
                                (round.title === ROUND_CONFIG.find(r => r.label === selectedRound)?.title ? 'active' : '')
                                : 'active'
                            }
                        >
                            {round.title}
                        </RoundTitle>
                        {round.matches?.map((match, matchIndex) => (
                            <div
                                key={matchIndex}
                                style={{
                                    position: 'relative',
                                    marginTop: isDesktop ?
                                        calculateMatchOffset(roundIndex, matchIndex, round.matches.length)
                                        : 0
                                }}
                            >
                                {renderMatch(match)}
                            </div>
                        ))}
                    </Round>
                ))}
            </BracketContainer>
        </>
    );
};