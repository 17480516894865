import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import Box from '@mui/material/Box';
import { v4 as uuidv4 } from 'uuid';
import Collapse from '@mui/material/Collapse';
import ReactGA from 'react-ga';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  CircularProgress,
  TableRow,
  MenuItem,
  Menu,
  Grid,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Chip,
  Autocomplete,
  TextField,
  Tabs,
  Tab
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState, useContext } from 'react';
import {
  AppWidgetSummary,

} from '../sections/@dashboard/app';
import Scrollbar from '../components/scrollbar';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { AuthContext } from '../utils/auth';
import { getTournamentById, getRegistrationsByTournamentId, createTournamentRegistrations, getUserByUserId, updateTournamentRegistrations } from "../api/ApiGateway";
import { getUserByEmailOrPhone } from '../utils/getUser';
import { TennisBracket } from './TournamentDraw';

const categories = [
  {
    name: 'U-10 Mixed (Round Robin format)',
    type: 'Round-robin',
    fee: 'INR 800'
  },
  {
    name: 'U-12 Boys & Girls (Round Robin format)',
    type: 'Round-robin',
    fee: 'INR 800'
  },
  {
    name: 'U-14 Boys & Girls (Round Robin format)',
    type: 'Round-robin',
    fee: 'INR 800'
  },
  {
    name: 'U-16 Boys & Girls (Round Robin format)',
    type: 'Round-robin',
    fee: 'INR 800'
  }
]

const TABLE_HEAD = [
  { id: 'registrationName', label: 'Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'isLighted', label: 'Lighted Courts', alignRight: false },
];

function capitalizeString(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user['Location Name'].toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export function formatDate(date) {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const formattedDate = new Date(date).toLocaleDateString('en-US', options);
  return formattedDate;
}

export function formatTime(time) {
  const options = { hour: 'numeric', minute: 'numeric', hour12: true };
  const formattedTime = new Date(time).toLocaleTimeString('en-US', options);
  return formattedTime;
}

export default function TournamentRegistrationPage() {
  const [open, setOpen] = useState(null);
  const [tournamentMeta, setTournamentMeta] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [tournamentRegistrations, setTournamentRegistrations] = useState([])
  const { tournamentId } = useParams();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [user, setUser] = useState({});
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [USERLIST, setUSERLIST] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [loggedInUserId, setLoggedInUserId] = useState('');
  const [isLoggedInUserHost, setIsLoggedInUserHost] = useState(false);
  const [isLoggedInUserRegistered, setIsLoggedInUserRegistered] = useState(false);
  const [tableHead, setTableHead] = useState([
    { id: 'registrationName', label: 'Name', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'category', label: 'Category', alignRight: false },
  ])
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(categories[0].name);
  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property === 'courtName' ? 'locationName' : property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.opponent);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const approveTournamentRegistration = async (recordId, tournamentId) => {
    try {
      setIsLoading(true);
      ReactGA.event({
        category: 'Tournament Registration',
        action: 'Approve',
        label: recordId
      });

      const data = {
        id: recordId,
        attributes: {
          tournamentId,
          registrationStatus: 'approved'
        }
      };

      await updateTournamentRegistrations(data);
      window.location.reload()
    } catch (error) {
      console.error('Error during registration approval', error);
      setIsLoading(false);
    }
  };

  const registerLoggedInUser = async (event) => {
    try {
      setIsLoading(true);
      // Assuming createTournamentRegistrations requires some parameters
      const currentDate = new Date();
      const currentISTDate = currentDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      console.log(currentISTDate)

      await createTournamentRegistrations({
        tournamentId,
        userId: loggedInUserId,
        userName: user.displayName,
        userEmail: user.email,
        userPhoneNumber: user.phoneNumber,
        id: uuidv4(),
        registrationStatus: 'pending',
        category: selectedCategory,
        fee: categories.find(category => category.name === selectedCategory).fee,
        registrationDate: currentISTDate.split(',')[0],
        registrationTime: currentISTDate.split(',')[1],
      });

      // Handle the response accordingly
      window.location.reload();

      // You might want to update state or perform other actions after registration
    } catch (error) {
      console.error('Error during registration', error);
      setIsLoading(false);
      // Handle the error, show a message, etc.
    }
  };


  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers.length) : 0;

  const isNotFound = !filteredUsers.length && !!filterName;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tournamentMetaResponse = await getTournamentById(tournamentId);
        setTournamentMeta(tournamentMetaResponse.data);
        const registrationsResponse = await getRegistrationsByTournamentId(tournamentId);
        // setTournamentRegistrations(registrationsResponse.data);
        setUSERLIST(registrationsResponse.data);
        const userId = await getUserByEmailOrPhone(currentUser);
        setLoggedInUserId(userId);
        const userData = await getUserByUserId(userId);
        console.log(userData.data)
        setUser(userData.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [tournamentId, currentUser]);

  useEffect(() => {
    // Ensure tournamentMeta.hosts is defined before accessing it
    if (tournamentMeta.hosts && tournamentMeta.hosts.length > 0) {
      const isHost = tournamentMeta.hosts.some(host => host.id === loggedInUserId);
      if (isHost) {
        setIsLoggedInUserHost(true);
        setTableHead([
          { id: 'registrationName', label: 'Name', alignRight: false },
          { id: 'status', label: 'Status', alignRight: false },
          { id: 'category', label: 'Category', alignRight: false },
          { id: 'fee', label: 'Fee', alignRight: false },
          { id: 'approval', label: 'Admin Actions', alignRight: false },
        ]);
      }
    }
  }, [loggedInUserId, tournamentMeta]);

  useEffect(() => {
    if (USERLIST) {
      const isRegistered = USERLIST.some(user => user.userId === loggedInUserId);
      setIsLoggedInUserRegistered(isRegistered);
    }
  }, [USERLIST, loggedInUserId]);


  return (
    <>
      <Helmet>
        <title>{`${tournamentMeta.tournamentName}`} | Playgrade</title>
      </Helmet>
      <TennisBracket />
      <Container maxWidth="lg">
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 'calc(100vh - 64px)', // Adjust this value based on your layout
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="tournament tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Tournament Details" />
              <Tab label="Results" />
            </Tabs>
            <br />
            {tabIndex === 0 && (<><Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <AppWidgetSummary title="Name" total={tournamentMeta.tournamentName} color='warning' />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <AppWidgetSummary title="Host" total={tournamentMeta.hosts.map(host => host.name).join(', ')} />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <AppWidgetSummary title="Reg fee (starting from)" total={categories.find(category => category.name === selectedCategory).fee} />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <AppWidgetSummary title="Tournament Type" total={tournamentMeta.tournamentType} />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <AppWidgetSummary title="Last registration date" total={formatDate(tournamentMeta.lastDateToRegister)} color='error' />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                  <AppWidgetSummary title="Date" total={formatDate(tournamentMeta.startDateTime)} />
                </Grid>
                <Grid item xs={6} sm={5} md={2}>
                  <AppWidgetSummary title="Time" total={formatTime(tournamentMeta.startDateTime)} />
                </Grid>
                <Grid item xs={6} sm={5} md={2}>
                  <AppWidgetSummary title="Duration" total={'2 days'} />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <AppWidgetSummary title="Category" total={selectedCategory} />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <AppWidgetSummary title="Location" total={tournamentMeta.courtName} color='secondary' />
                </Grid>
              </Grid>
              <br />
              <Typography variant="subtitle1" sx={{ mb: 2, fontSize: 20 }}>

                {tournamentMeta.notes}
              </Typography>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                {!isLoggedInUserRegistered && <Autocomplete
                  disablePortal
                  id="category-selection"
                  value={selectedCategory}
                  options={categories.map((option) => option.name)}
                  fullWidth
                  sx={{ marginTop: 1, marginBottom: 1, width: '100%' }}
                  renderInput={(params) => <TextField {...params} label="Category" />}
                  required
                  Uncomment this line if you need to handle category change events
                  onChange={handleCategoryChange}
                />}

                {!isLoggedInUserRegistered && <Button
                  id="new-session-button"
                  variant="contained"
                  onClick={registerLoggedInUser}
                >
                  Register
                </Button>}
              </Stack>
            </Box>
              <Card>
                <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={tableHead}
                        rowCount={USERLIST.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                        showCheckBox={isLoggedInUserHost}
                      />
                      <TableBody>
                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                          const { userName: name, registrationStatus: status, userId, id } = row;
                          console.log(row)
                          return (
                            <>
                              <TableRow hover key={id} tabIndex={-1} >
                                {isLoggedInUserHost && <TableCell padding="checkbox">
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen((prevOpen) => (prevOpen === id ? null : id))}
                                  >
                                    {open === id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </TableCell>}
                                <TableCell align="left">
                                  {/* Use Link component to create a hyperlink */}
                                  <Link
                                    to={`/v2/profile/${userId}`}
                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                  >
                                    {name}
                                  </Link>
                                </TableCell>
                                <TableCell align="left">{capitalizeString(status)}</TableCell>
                                <TableCell align="left">{capitalizeString(row.category)}</TableCell>
                                {isLoggedInUserHost && <TableCell align="left">{capitalizeString(row.fee)}</TableCell>}
                                {isLoggedInUserHost && <TableCell align="left">
                                  {status !== 'approved' && (
                                    <Button
                                      id="new-session-button"
                                      variant="contained"
                                      onClick={() => approveTournamentRegistration(id, tournamentId)}
                                      sx={{ bgcolor: '#229A16' }}
                                    >
                                      Approve
                                    </Button>
                                  )}
                                </TableCell>}
                              </TableRow>
                              {isLoggedInUserHost && <TableRow>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                  <Collapse in={open === id} timeout="auto" unmountOnExit>
                                    <Box sx={{ margin: 1 }}>
                                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
                                        Additional Information:
                                      </Typography>
                                      <Typography variant="body2" color="text.secondary">
                                        {row.userEmail && `Email : ${row.userEmail}`}
                                        {row.userPhoneNumber && `Phone : ${row.userPhoneNumber}`}
                                      </Typography>
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>}
                            </>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>


                      {isNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Not found
                                </Typography>

                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Try checking for typos or using complete words.
                                </Typography>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={filteredUsers.length}  // Use filteredUsers.length instead of USERLIST.length
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </>)}
          </>
        )}

      </Container></>
  );
}
